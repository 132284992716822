
import Routes from "@/Routes";
import { Component, Vue, Watch } from "vue-property-decorator";
import AppEvents from "@/AppEvents";
import AuthStore from "@/store/AuthStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PasswordResetRequest from "@/models/PasswordResetRequest";
import { RouteConfig } from "vue-router";

@Component({
  components: {
  },
})
export default class ResetPasswordPage extends Vue {
  loading = false;
  passwordVisible = false;
  confirmPasswordVisible = false;
  email = "";
  password = "";
  confirmPassword = "";
  newPasswordSaved = false;
  resetTokenIsValid = false;
  validatingToken = true;

  get rules(): { [key: string]: any; } {
    return {
     
      passwordMinLength: (v: any) =>
        v.length >= 10 || 
        this.$t('generic.validation.passwordMinLength', { number: 10 }),
      passwordContainsUppercase: (v: any) => 
        /[A-Z]/.test(v) ||
        this.$t('generic.validation.passwordContainsUppercase'),
      passwordContainsLowercase: (v: any) => 
        /[a-z]/.test(v) ||
        this.$t('generic.validation.passwordContainsLowercase'),
      passwordContainsNumber: (v: any) => 
        /[0-9]/.test(v) || 
        this.$t('generic.validation.passwordContainsNumber'),
      confirmPassword: (v: any) => 
        this.password === this.confirmPassword ||
        this.$t('generic.validation.confirmPassword')
    }
  }

  async created() {
    await this.validateResetToken();
  }

  async validateResetToken() {
    this.validatingToken = true;

    if (this.resetToken) {
      this.resetTokenIsValid = await AuthStore.validatePasswordResetToken(this.resetToken);
    }
    this.validatingToken = false;
  }

  async onSaveNewPasswordClick() {
    if (!this.resetToken || !(this.$refs.newPasswordForm as any).validate()) {
      return;
    }

    this.loading = true;

    const passwordResetRequest = new PasswordResetRequest(this.resetToken, this.password, this.confirmPassword);
    const success = await AuthStore.resetPassword(passwordResetRequest);
    if (success) {
      this.newPasswordSaved = true;
    }
    
    this.loading = false;
  }

  get resetToken(): string | null {
    return this.$route.query.t 
      ? this.$route.query.t.toString()
      : null;
  }

  get signinRoute(): RouteConfig {
    return Routes.signin;
  }
}
