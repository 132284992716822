export default class PasswordResetRequest {
  passwordResetToken: string;
  password: string;
  confirmPassword: string;

  constructor(passwordResetToken: string, password: string, confirmPassword: string) {
    this.passwordResetToken = passwordResetToken;
    this.password = password;
    this.confirmPassword = confirmPassword;
  }
}
